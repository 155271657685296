import Layout from "@/components/layout";
import Image from "next/image";
import Link from "next/link";
import { getEntityImage } from "lib/basic";
import { useAppContext } from "./../context/state";

function Custom404() {
    const AppContext = useAppContext();

    return (
        <Layout
            seo={{
                title: "Page not found",
                description: "Page not found",
            }}
        >
            <div className="ltx-404">
                <Image src={"/404.png"} width={612} height={603} />
                <br />
                <br />
                <br />
                <Link href="/">
                    <button>Go Back Home</button>
                </Link>
                <br />
                <br />

                <h2>Check out our latest stories!</h2>
                <div className="ltx_SuggestedArticles">
                    {AppContext.suggestedArticles &&
                        AppContext.suggestedArticles
                            .slice(0, 3)
                            .map((article: any, aIndex: number) => (
                                <Link href={article.parsed_url} key="aIndex">
                                    <div className="ltx_ArticleBox">
                                        <div>
                                            <div
                                                className="ltx_Image"
                                                style={{
                                                    backgroundImage: `url(${getEntityImage(
                                                        article,
                                                        "image",
                                                        "thumbnail",
                                                        true
                                                    )})`,
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>{article.section.name}</span>
                                            <div>{article.title}</div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                </div>
            </div>
        </Layout>
    );
}

export default Custom404;
